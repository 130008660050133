<template>


  <div v-if="!is_load">

    <ol class="breadcrumb">
      <li class="breadcrumb-item">Trang chủ</li>
      <li class="breadcrumb-item">
       
        <router-link to="/admin/vehicle/lists"> Quản lý Tuyến xe</router-link>
      </li>

      <li class="breadcrumb-item"> Import  Tuyến xe</li>
    </ol>
     <FormValidate v-slot="{ invalid ,reset }">

    <form   @submit.prevent="onSubmit" @reset.prevent="reset" id="sendform">
      
      <div class="container">
       
        <!-- Tab panes -->
        <div class="tab-content">

      

          <div id="home" class="container tab-pane active">
            <br />
            <div class="">
               <div class="col-sm-12">
                                <div class="card">
                                    <div class="card-header">
                                        <strong>Thông tin   </strong>
                                        <small>Form</small>
                                    </div>
                                    <div class="card-body">
                                        <div class="row">
                                           
                                           
                                            <div class="col-sm-10">

                                                <div class="form-group">
                                                    <label for="name"> File Excel ( <span  class="text-danger">*</span> ) </label>
                 
                    <export-excel
  class   = "btn btn-default"

    :data   = "json_data"
    :fields = "json_fields"
    worksheet = "My Worksheet"
    type    = "xlsx"
    name    = "filename.xlsx">
 
    Download Excel (you can customize this with html code!)
 
</export-excel>


                   
                                                </div>

                                            </div>

                                          

                                    
                                     



                                            
                                       
                                    </div>
                                </div>

               
                            </div>

                         
                            
                
                  
             </div>


            </div>
          </div>
          

          
          
          <div
            class="card-footer"
            style="width: 90%; position: fixed; bottom: 0"
          >
          <p class="text text-info" v-if="invalid"> Vui lòng nhập đầy đủ dữ liệu </p>
            <button
              type="submit"
              class="btn btn-sm btn-primary"
              :disabled="invalid"
              
            >
              <i class="fad fa-save"></i> IMPORT 
            </button>
       
            
             
    
           
          </div>
        </div>
      </div>
    </form>
  </FormValidate>
  </div>
  <div v-else>
        <MyLoadTheme>
             
                <MyLoading v-for="k in 10" :key="k"></MyLoading>
           
        </MyLoadTheme>
  </div>
</template>

<script>
import axios from "axios";
import Vue from 'vue'
import excel from 'vue-excel-export'
 
Vue.use(excel)

  
export default {
  data() {
    return {
      is_load: false,
      
      isLogin: null,
       json_fields: {
            'Complete name': 'name',
            'City': 'city',
            'Telephone': 'phone.mobile',
            'Telephone 2' : {
                field: 'phone.landline',
                callback: (value) => {
                    return `Landline Phone - ${value}`;
                }
            },
        },
        json_data: [
            {
                'name': 'Tony Peña',
                'city': 'New York',
                'country': 'United States',
                'birthdate': '1978-03-15',
                'phone': {
                    'mobile': '1-541-754-3010',
                    'landline': '(541) 754-3010'
                }
            },
            {
                'name': 'Thessaloniki',
                'city': 'Athens',
                'country': 'Greece',
                'birthdate': '1987-11-23',
                'phone': {
                    'mobile': '+1 855 275 5071',
                    'landline': '(2741) 2621-244'
                }
            }
        ],
        json_meta: [
            [
                {
                    'key': 'charset',
                    'value': 'utf-8'
                }
            ]
        ],

      
     
    };
  },
  created() {
    let isLogin = this.$session.get("isLogin");
    this.isLogin = isLogin.permistions.split(",");
    
  },
  
  mounted() {
   
  },
  methods: {
    
    
    onSubmit: function () {
        let myForm = document.getElementById("sendform");

       let formData = new FormData(myForm);
        
        

      
       
          formData.append("code_en",formData.get('code'));
 
         formData.append("km_en",formData.get('km'));
         formData.append("price_en",formData.get('price'));
         formData.append("price1_en",formData.get('price1'));
         formData.append("timer_en",formData.get('timer'));
         formData.append("route_en",formData.get('route'));
           

       
        
        axios
          .post(window.my_api + "api/vehicle/create-or-update-vehicle", formData, {
            headers: window.my_header,
          })
          .then((res) => {

            if (res.status == 200) {
               if(res.data.successed){
                    this.$swal.fire({
                        position: "top-end",
                        icon: "success",
                        title: "Thêm thành công.",
                        showConfirmButton: false,
                        timer: 2500,
                      });
                      
                      this.myreset();
               }else{
                      this.$swal.fire({
                        position: "top-end",
                        icon: "error",
                        title: "Vui lòng kiểm tra lại dữ liệu.",
                        showConfirmButton: false,
                        timer: 2500,
                      });
                      
                     
               }
            

            } else {
              alert("Thêm không thành công, vui lòng liên hệ admin");
             
            }
             this.is_load = false;
          });
      
    },
  },
};
</script>